// extracted by mini-css-extract-plugin
export var multiVendorBenefitsSection = "x_dq";
export var multiVendorBusinessesSection = "x_dj";
export var multiVendorClinetsSection = "x_dv";
export var multiVendorDevExpertiseSection = "x_dr";
export var multiVendorDiscoverWorksSection = "x_dp";
export var multiVendorFeaturesSection = "x_dh";
export var multiVendorIdeaToLifeSection = "x_dn";
export var multiVendorIndustriesSection = "x_dk";
export var multiVendorOurAchievementsSection = "x_dt";
export var multiVendorProcessSection = "x_dl";
export var multiVendorServicesSection = "x_dg";
export var multiVendorSuccessStoriesSection = "x_ds";
export var multiVendorTechStackSection = "x_dm";